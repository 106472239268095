<!--
 * @Description: 基础信息-供应商列表-新增/编辑供应商弹框
 * @Autor: 胡椒
 * @Date: 2020-07-30 14:03:39
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-08 17:14:39
-->
<template>
  <a-modal
    :visible="visible"
    :title="modalTitle"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
    >
      <a-form-item label="供应商名称">
        <a-input
          v-decorator="[
            'supplier_name',
            {
              initialValue: data.supplier_name,
              rules: validatorRules.supplier_name,
            },
          ]"
          :maxLength="30"
        />
      </a-form-item>

      <a-form-item label="编码">
        <a-input
          v-decorator="[
            'supplier_code',
            {
              initialValue: data.supplier_code || '',
            },
          ]"
          :maxLength="30"
        />
      </a-form-item>

      <a-form-item label="联系人">
        <a-input
          v-decorator="[
            'contact_people',
            {
              initialValue: data.contact_people,
            },
          ]"
          :maxLength="30"
        />
      </a-form-item>

      <a-form-item label="联系手机">
        <a-input
          v-decorator="[
            'contact_mobile',
            {
              initialValue: data.contact_mobile,
              rules: validatorRules.contact_mobile,
            },
          ]"
          :maxLength="30"
        />
      </a-form-item>

      <a-input
        type="hidden"
        v-decorator="['docking_user', { initialValue: data.docking_user }]"
      ></a-input>
      <a-form-item label="折一对接人">
        <a-select
          v-decorator="[
            'docking_user__username',
            {
              initialValue: data.docking_user
                ? `${data.docking_user}-${data.docking_user__username}`
                : undefined,
              rules: validatorRules.docking_user__username,
            },
          ]"
          show-search
          allowClear
          :filter-option="filterOption"
          placeholder="请选择"
        >
          <a-select-option
            v-for="item in userList"
            :value="`${item.id}-${item.username}`"
            :key="item.id"
            >{{ item.username }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item label="备注">
        <a-textarea
          v-decorator="[
            'note',
            {
              initialValue: data.note,
            },
          ]"
          :autoSize="{ minRows: 4, maxRows: 10 }"
          size="large"
          allow-clear
          :maxLength="100"
          placeholder="请输入备注"
        />
      </a-form-item>

      <a-row type="flex" justify="center" :gutter="10">
        <a-col>
          <a-button @click="handleCancel" class="mr4">取消</a-button>
        </a-col>
        <a-col>
          <a-button type="primary" html-type="submit">确定</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import { checkPhoneNumber } from '@/utils/validator';
import { addSupplier, editSupplier } from '@/service/supplier';
import { getAdminUserBriefList } from '@/service/user';

export default {
  props: {
    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },

    // 新建or编辑
    mode: {
      type: String,
      default: 'add',
      validator: (value) => {
        return ['add', 'edit'].indexOf(value) !== -1;
      },
    },

    // 回显数据
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShow: this.visible,

      // 折一对接人select data
      userList: [],

      // 表单&&验证规则
      form: this.$form.createForm(this),
      validatorRules: {
        // 供应商名称
        supplier_name: [
          {
            required: true,
            message: '请输入供应商名称',
          },
        ],

        // 联系手机
        contact_mobile: [
          {
            validator: checkPhoneNumber,
          },
        ],

        // 折一对接人
        docking_user__username: [
          {
            required: true,
            message: '请选择折一对接人',
          },
        ],
      },
    };
  },

  computed: {
    modalTitle() {
      return `${this.mode === 'add' ? '新建' : '编辑'}供应商`;
    },
  },

  async created() {
    this.getUserList(); // 获取折一对接人select数据
  },

  methods: {
    // 提交表单
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        const params = Object.assign({}, values);

        // 对接人id&对接人名称处理 eg: 张三-1001 -> 张三 1001
        if (params.docking_user__username) {
          const temp = params.docking_user__username.split('-');
          params.docking_user = temp[0];
          params.docking_user__username = temp[1];
        }

        // postdata
        const { res } =
          this.mode === 'edit'
            ? await editSupplier(this.data.id, params)
            : await addSupplier(params);
        if (res && res.success) {
          this.form.resetFields();
          this.$emit('success');
        }
      });
    },

    // 获取折一对接人列表
    async getUserList() {
      const { res } = await getAdminUserBriefList();
      if (res && res.success) {
        this.userList = res.data;
      }
    },

    // 折一对接人select输入过滤
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    // 关闭弹框
    handleCancel() {
      this.form.resetFields();
      this.$emit('close');
    },
  },
};
</script>
