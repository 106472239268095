var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.modalTitle,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"供应商名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'supplier_name',
          {
            initialValue: _vm.data.supplier_name,
            rules: _vm.validatorRules.supplier_name,
          } ]),expression:"[\n          'supplier_name',\n          {\n            initialValue: data.supplier_name,\n            rules: validatorRules.supplier_name,\n          },\n        ]"}],attrs:{"maxLength":30}})],1),_c('a-form-item',{attrs:{"label":"编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'supplier_code',
          {
            initialValue: _vm.data.supplier_code || '',
          } ]),expression:"[\n          'supplier_code',\n          {\n            initialValue: data.supplier_code || '',\n          },\n        ]"}],attrs:{"maxLength":30}})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contact_people',
          {
            initialValue: _vm.data.contact_people,
          } ]),expression:"[\n          'contact_people',\n          {\n            initialValue: data.contact_people,\n          },\n        ]"}],attrs:{"maxLength":30}})],1),_c('a-form-item',{attrs:{"label":"联系手机"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contact_mobile',
          {
            initialValue: _vm.data.contact_mobile,
            rules: _vm.validatorRules.contact_mobile,
          } ]),expression:"[\n          'contact_mobile',\n          {\n            initialValue: data.contact_mobile,\n            rules: validatorRules.contact_mobile,\n          },\n        ]"}],attrs:{"maxLength":30}})],1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['docking_user', { initialValue: _vm.data.docking_user }]),expression:"['docking_user', { initialValue: data.docking_user }]"}],attrs:{"type":"hidden"}}),_c('a-form-item',{attrs:{"label":"折一对接人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'docking_user__username',
          {
            initialValue: _vm.data.docking_user
              ? ((_vm.data.docking_user) + "-" + (_vm.data.docking_user__username))
              : undefined,
            rules: _vm.validatorRules.docking_user__username,
          } ]),expression:"[\n          'docking_user__username',\n          {\n            initialValue: data.docking_user\n              ? `${data.docking_user}-${data.docking_user__username}`\n              : undefined,\n            rules: validatorRules.docking_user__username,\n          },\n        ]"}],attrs:{"show-search":"","allowClear":"","filter-option":_vm.filterOption,"placeholder":"请选择"}},_vm._l((_vm.userList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":((item.id) + "-" + (item.username))}},[_vm._v(_vm._s(item.username))])}),1)],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'note',
          {
            initialValue: _vm.data.note,
          } ]),expression:"[\n          'note',\n          {\n            initialValue: data.note,\n          },\n        ]"}],attrs:{"autoSize":{ minRows: 4, maxRows: 10 },"size":"large","allow-clear":"","maxLength":100,"placeholder":"请输入备注"}})],1),_c('a-row',{attrs:{"type":"flex","justify":"center","gutter":10}},[_c('a-col',[_c('a-button',{staticClass:"mr4",on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1),_c('a-col',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确定")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }