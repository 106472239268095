<!--
 * @Description: 基础信息-供应商列表
 * @Autor: 胡椒
 * @Date: 2020-07-30 09:55:27
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-08 17:21:43
-->
<template>
  <div>
    <a-form :form="form" @submit="handleSubmit" layout="inline">
      <a-form-item>
        <a-select
          @change="setField"
          :defaultValue="currentField"
          style="width: 115px;"
          placeholder="请选择"
        >
          <a-select-option
            v-for="item in selectKeysOptions"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-input
          v-decorator="[currentField]"
          :placeholder="`请输入${currentFieldLabel}`"
          :key="currentField"
        />
      </a-form-item>

      <a-form-item>
        <a-button
          v-auth="'basic_supplier_page'"
          type="primary"
          :loading="loading"
          html-type="submit"
          >查询</a-button
        >
      </a-form-item>
    </a-form>

    <a-row class="tool-bar">
      <a-button v-auth="'basic_supplier_add'" type="primary" @click="add"
        >添加供应商</a-button
      >
    </a-row>

    <a-table
      :scroll="{ y: tableScrollY }"
      v-auth="'basic_supplier_page'"
      :rowKey="(record) => record.id"
      :columns="columns"
      :dataSource="list"
      :pagination="pagination"
      :loading="loading"
      :locale="{ filterConfirm: '暂无数据' }"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="record">
        <a-button
          v-auth="'basic_supplier_modify'"
          type="primary"
          size="small"
          @click="edit(record)"
          >编辑</a-button
        >
      </template>
    </a-table>

    <!-- 新建/编辑供应商弹框 -->
    <ModalSupplierEdit
      :mode="editMode"
      :visible="isAddEditModalVisible"
      :data="currentRecord"
      @success="opearteSuccess"
      @close="isAddEditModalVisible = false"
    ></ModalSupplierEdit>
  </div>
</template>

<script>
import { supplierList } from '@/service/supplier';
import { value2label } from '@/utils/index';
import listMixin from '@/mixin/listMixin';
import ModalSupplierEdit from '@/components/admin/supplier/supplier-modal-edit';

const selectKeysOptions = [
  { label: '供应商名称', value: 'supplier_name' },
  { label: '编码', value: 'supplier_code' },
];

const dftValue = {
  supplier_code: '',
  supplier_name: '',
};

const columns = [
  {
    title: '供应商名称',
    dataIndex: 'supplier_name',
    align: 'center',
  },
  {
    title: '编码',
    dataIndex: 'supplier_code',
    width: 160,
    align: 'center',
  },
  {
    title: '联系人',
    dataIndex: 'contact_people',
    align: 'center',
  },
  {
    title: '联系手机',
    dataIndex: 'contact_mobile',
    align: 'center',
  },
  {
    title: '折一对接人',
    dataIndex: 'docking_user__username',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'note',
    align: 'center',
  },
  {
    title: '操作',
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'supplierList',
  mixins: [listMixin],
  components: {
    ModalSupplierEdit,
  },
  data() {
    return {
      tableScrollY: 600,
      // 供应商弹框
      isAddEditModalVisible: false, // 是否显示
      editMode: 'add', // 编辑模式, add or edit
      currentRecord: {}, // 数据载体

      // 检索用表单
      form: this.$form.createForm(this),

      // 列表相关
      columns: columns,
      searchParams: {
        ...dftValue,
      },

      // 下拉options
      currentField: selectKeysOptions[0].value,
      selectKeysOptions,
    };
  },

  computed: {
    // input的placeholder
    currentFieldLabel() {
      return value2label(this.currentField, selectKeysOptions);
    },
  },

  created() {
    this.getList();
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 345;
  },

  methods: {
    // 检索表单提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const proload = Object.assign({}, dftValue, values);
          this.searchParams = Object.assign({}, this.searchParams, proload);
          this.pagination.current = 1;
          this.getList();
        }
      });
    },

    // 列表数据检索
    async getList() {
      this.loading = true;
      const { res } = await supplierList({
        ...this.searchParams,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.loading = false;
      if (res && res.success) {
        this.list = res.data.results;
        const pagination = { ...this.pagination };
        pagination.total = res.data.count;
        this.pagination = pagination;
      }
    },

    // 用户编辑
    add() {
      this.editMode = 'add';
      this.currentRecord = {};
      this.showModal(true);
    },

    // 用户新建
    edit(preload) {
      this.editMode = 'edit';
      this.currentRecord = preload;
      this.showModal(true);
    },

    showModal(flag) {
      this.isAddEditModalVisible = !!flag;
    },

    // 新增/编辑供应商后，列表同步
    opearteSuccess() {
      this.showModal(false);
      this.$message.success('操作成功');
      this.getList();
    },

    setField(value) {
      this.currentField = value;
    },
  },
};
</script>

<style lang="less" scoped>
.tool-bar {
  margin: 10px 0;
}
</style>
